import React from "react"
import { Box, Typography, useMediaQuery } from "@mui/material"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import FullWidthStripe from "../components/FullWidthStripe"
import Layout from "../components/layout"

import "swiper/css"
import "swiper/css/pagination"
import FeaturedResource from "../components/featured-resources/FeaturedResource"
import Empty from "../components/Empty"

const ShiftingRow = ({ items, shiftDirection }) => (
  <Box
    className={`shifting-row-${shiftDirection}`}
    width={1}
    display="flex"
    flexWrap={{ xs: "wrap", lg: "nowrap" }}
    gap={5}
    ml={{
      xs: 0,
      lg: shiftDirection === "left" ? "-45%" : "-10%",
      xl: shiftDirection === "left" ? "-25%" : "-10%",
    }}
    sx={{
      transition: "margin-left 0.5s; margin-right: 0.5s",
    }}
  >
    {items.map(({ defaultImage, highlightedImage }, index) => (
      <Box
        className="higlighted-image-card"
        key={index}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={132}
        flex={{ xs: "1 100%", md: "1 45%", lg: "1 20%" }}
        minWidth={265}
        borderRadius={1}
        boxShadow={26}
        position="relative"
        sx={{
          transition: "box-shadow 0.5s",
        }}
      >
        <GatsbyImage
          image={getImage(defaultImage.localFile)}
          alt="gray client logo"
        />
        <Box
          className="higlighted-image"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          width={1}
          height={1}
          sx={{
            opacity: 0,
            transition: "opacity 0.5s",
          }}
        >
          <GatsbyImage
            image={getImage(highlightedImage.localFile)}
            alt="colorful client logo"
          />
        </Box>
      </Box>
    ))}
  </Box>
)

const getSwiperStyles = ({ palette }) => ({
  ".swiper": {
    marginLeft: -2,
  },
  ".swiper-pagination": {
    bottom: "0px !important",
  },
  ".swiper-pagination-bullet-active": {
    bgcolor: palette.primary.main,
  },
})
const FeaturedCaseStudies = ({ resourceURI, firstRow, secondRow }) => {
  const isLgUp = useMediaQuery(theme => theme.breakpoints.up("xl"))
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up("md"))

  return (
    <Box
      width={1}
      maxWidth={1}
      display="flex"
      flexDirection="column"
      gap={5}
      sx={theme => ({ ...getSwiperStyles(theme) })}
    >
      <Box maxWidth={1}>
        <Swiper
          modules={[Pagination]}
          slidesPerView={isLgUp ? 3 : isMdUp ? 2 : 1}
          pagination
        >
          {firstRow.map((resource, index) => (
            <SwiperSlide key={resource.strapi_id || index}>
              <FeaturedResource resourceURI={resourceURI} {...resource} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>

      <Box maxWidth={1}>
        <Swiper
          modules={[Pagination]}
          slidesPerView={isLgUp ? 3 : isMdUp ? 2 : 1}
          pagination
        >
          {secondRow.map((resource, index) => (
            <SwiperSlide key={resource.strapi_id || index}>
              <FeaturedResource resourceURI={resourceURI} {...resource} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

const ClientsPage = () => {
  const {
    strapiClientsPage: { overline, header, firstRow, secondRow, thirdRow },
    allStrapiCaseStudy: { nodes },
  } = useStaticQuery(clientsPageQuery)

  const caseStudies = nodes.filter(
    ({ readTime }) => readTime && readTime.toLowerCase() !== "dummy"
  )

  return (
    <Layout>
      <Box
        mt={{ xs: 1, lg: 9 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography fontWeight={400} variant="overline" color="primary.main">
          {overline}
        </Typography>
        <Typography variant="h3">{header}</Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        gap={5}
        p={1}
        sx={{
          overflowX: "hidden",
          "&:hover": {
            ".shifting-row-right": {
              ml: { lg: "-750px", xl: "-470px" },
            },
            ".shifting-row-left": {
              ml: { lg: "0px" },
            },
            ".higlighted-image": {
              opacity: 1,
            },
            ".higlighted-image-card": {
              boxShadow: 27,
            },
          },
        }}
      >
        <ShiftingRow items={firstRow} shiftDirection="right" />
        <ShiftingRow items={secondRow} shiftDirection="left" />
        <ShiftingRow items={thirdRow} shiftDirection="right" />
      </Box>

      <FullWidthStripe bgcolor="background.gray" py={11} px={{ xs: 3, sm: 15 }}>
        <Box
          mx="auto"
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth={650}
          mb={5}
        >
          <Typography
            fontWeight={500}
            variant="overline"
            color="primary.main"
            sx={{ mb: 0.5 }}
          >
            Featured case studies
          </Typography>
          <Typography variant="h1" textAlign="center">
            Read more about our client’s success stories
          </Typography>
        </Box>

        {caseStudies.length !== 0 && (
          <FeaturedCaseStudies
            resourceURI="/case-studies"
            firstRow={caseStudies?.slice(0, 3)}
            secondRow={caseStudies?.slice(3)}
          />
        )}

        {caseStudies.length === 0 && <Empty />}
      </FullWidthStripe>
    </Layout>
  )
}

const clientsPageQuery = graphql`
  query ClientsPageQuery {
    strapiClientsPage {
      overline
      header

      firstRow {
        uri
        defaultImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        highlightedImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      secondRow {
        uri
        defaultImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        highlightedImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      thirdRow {
        uri
        defaultImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        highlightedImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }

    allStrapiCaseStudy(sort: { order: DESC, fields: publishedAt }, limit: 6) {
      nodes {
        strapi_id
        publishedAt
        readTime
        header {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            url
          }
        }
      }
    }
  }
`

export default ClientsPage
