import React from "react"
import { Box, Link, IconButton, Typography } from "@mui/material"

import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa"

import Construction from "../images/construction.svg"

const Empty = ({
  header = "No content",
  description = `Follow us on our social media networks and get notified when the content becomes available.`,
}) => {
  return (
    <Box
      mx="auto"
      display="flex"
      flexDirection="column"
      flexWrap="wrap"
      gap={2}
      width={{ lg: 0.33 }}
      alignItems="center"
    >
      <Box
        display="flex"
        flex={1}
        sx={{
          img: {
            width: { xs: 250, sm: 400 },
          },
        }}
      >
        <img src={Construction} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        gap={2}
        justifyContent="center"
      >
        <Typography variant="h5" textAlign="center">
          {header}
        </Typography>
        <Typography variant="body2" textAlign="center">
          {description}
        </Typography>
        <Box display="flex" gap={1} justifyContent="center">
          <Link to="https://www.facebook.com/InfoStudiodoo">
            <IconButton
              aria-label="Facebook link"
              sx={{ border: "0.3px solid #9CC2FE" }}
            >
              <FaFacebookF color="#2E6ACA" size={18} />
            </IconButton>
          </Link>
          <Link to="https://www.linkedin.com/company/info-studio-d-o-o-sarajevo/mycompany/">
            <IconButton
              aria-label="LinkedIn link"
              sx={{ border: "0.3px solid #9CC2FE" }}
            >
              <FaLinkedinIn color="#2E6ACA" size={18} />
            </IconButton>
          </Link>
          <Link to="https://www.instagram.com/infostudio.doo/">
            <IconButton
              aria-label="Instagram link"
              sx={{ border: "0.3px solid #9CC2FE" }}
            >
              <FaInstagram color="#2E6ACA" size={18} />
            </IconButton>
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default Empty
